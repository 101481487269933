import Experience from '../../Experience.js'
import * as THREE from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js'

export default class PositionHelper {
    constructor(debugUI) {
        // Setup
        this.experience = new Experience()
        this.debug = debugUI

        this.setTransform()

        // Add Methods to UI
        this.folder = this.debug.ui.addFolder('Position Helper').close()
        this.folder.add(this, 'getPoint')
        this.folder.add(this, 'getCamera')
        this.folder.add(this, 'getCameraTarget')
        this.folder.add(this, 'getCameraAngles')
        this.folder.add(this, 'toggle')
    }
    
    setTransform() {
        // Mesh
        this.mesh = new THREE.Mesh(
            new THREE.OctahedronGeometry(2, 0),
            new THREE.MeshBasicMaterial({ color: 'yellow' })
        )

        // Transform Controls
        this.controls = new TransformControls(
            this.experience.camera.instance,
            this.experience.canvas
        )
    
        const camera = this.experience.camera
        this.controls.addEventListener('dragging-changed', function (event) {
            if(!camera.controls.enabled) return
            camera.controls.enabled = !event.value
        })
    
        this.experience.scene.add(this.mesh)
        this.controls.attach(this.mesh)
        this.experience.scene.add(this.controls)

        this.toggle()
    }

    getPoint() {
        const coordinate = this.mesh.position
        console.log(coordinate.x + ', ' + coordinate.y + ', ' + coordinate.z)
    }

    getCamera() {
        const coordinate = this.experience.camera.instance.position
        console.log(coordinate.x + ', ' + coordinate.y + ', ' + coordinate.z)
    }

    getCameraTarget() {
        const coordinate = this.experience.camera.controls.target
        console.log(coordinate.x + ', ' + coordinate.y + ', ' + coordinate.z)
    }

    getCameraAngles() {
        const azimuth = this.experience.camera.controls.getAzimuthalAngle()
        const polar = this.experience.camera.controls.getPolarAngle()
        const distance = this.experience.camera.controls.getDistance()
        console.log('Azimuth: ' + azimuth + ', Polar: ' + polar + ', Distance: '+distance)
    }

    toggle() {
        this.controls.visible = !this.controls.visible
        this.mesh.visible = !this.mesh.visible
    }
}
