import Experience from '../Experience'
import anime from 'animejs'
import cameraData from './cameraData.json'
import cameraData_mobile from './cameraData_mobile.json'

export default class CameraFlight {
    constructor() {
        this.experience = new Experience()

        this.createPoints()
        this.camera = this.experience.camera
        this.points = this.experience.ui.points
    }

    createPoints() {
        const mobileBreakpoint = window.matchMedia('(max-width:762px)')

        const data = mobileBreakpoint.matches? cameraData_mobile : cameraData

        for (let entry of data) {
            // Retrieve Data
            const name = entry.name || 'initial'
            const position = entry.position
            const target = entry.target
            const duration = entry.duration || 1500

            this.createCameraFly(name, position, target, duration)
        }
    }
    
    createCameraFly(name, position, target, duration) {
        this[name] = function () {
            anime({
                targets: this.camera.instance.position,
                x: position.x,
                y: position.y,
                z: position.z,
                easing: 'easeInOutQuart',
                duration: duration,
                begin: () => {
                    this.points.toggleHide()
                },
                complete: () => {
                    this.points.toggleHide()
                }
            })
    
            anime({
                targets: this.camera.controls.target,
                x: target.x,
                y: target.y,
                z: target.z,
                easing: 'easeInOutQuart',
                duration: duration,
            })
        }
    }
}
