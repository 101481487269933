import Experience from "../Experience"

export default class Fullscreen {
    constructor(ui) {
        this.experience = new Experience()
        this.ground = this.experience.world.ground
        this.controls = this.experience.camera.controls
        this.ui = ui
        this.points = ui.points
        
        
        this.fullscreenContainer = document.body.querySelector('#threemap-fullscreen-container')
        
        this.fullscreenContainer.onclick = () => this.activate()
    }
    
    activate() {
        // Check if all geometry is loaded
        if(!this.ground.isReady()) return
        
        this.fullscreenContainer.onclick = null
        
        // Show Container over whole page, WIP
        this.fullscreenContainer.scrollIntoView({
            behavior: 'smooth'
        })
        this.fullscreenContainer.classList.add('fullscreen')
        document.body.addEventListener('wheel', this.preventScroll, {passive: false} )
        
        // Start Initial Flight
        this.ui.startFlight()

        // Activate Controls
        this.controls.enabled = true
        
        // Show Exit Buttons
    }

    exit() {
        // Deactivate Controls
        this.controls.enabled = false

        // Hide Exit Buttons
        

        // Minify Container
        this.fullscreenContainer.classList.remove('fullscreen')
        document.body.removeEventListener('wheel', this.preventScroll)

        // Listen for click event again
        setTimeout(() => {
            this.fullscreenContainer.onclick = () => this.activate()
        }, 1500)
    }

    preventScroll(e) {
        e.preventDefault();
        e.stopPropagation();
    
        return false;
    }
}