import * as THREE from 'three'
import Experience from './Experience.js'

    export default class Renderer {
    constructor() {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.screenSpace = this.experience.screenSpace
        this.scene = this.experience.scene
        this.camera = this.experience.camera

        // this.debug = this.experience.debug

        this.setInstance()
    }

    setInstance() {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
        })

        // Realistic Rendering
        this.instance.physicallyCorrectLights = true
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.CineonToneMapping
        this.instance.toneMappingExposure = 1.75

        // this.instance.setClearColor('#211d20')

        // Resolution
        this.instance.setSize(this.screenSpace.width, this.screenSpace.height)
        this.instance.setPixelRatio(Math.min(this.screenSpace.pixelRatio, 2))
    }

    resize() {
        this.instance.setSize(this.screenSpace.width, this.screenSpace.height)
        this.instance.setPixelRatio(Math.min(this.screenSpace.pixelRatio, 2))
    }

    update() {
        this.instance.render(this.scene, this.camera.instance)
    }
}
