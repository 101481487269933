import * as THREE from 'three'
import EventEmitter from './EventEmitter'
 
export default class SceneSizes extends EventEmitter {
    constructor() {
        super()

        this.size = 1
        this.center = null
    }

    setBase(model) {
        const box = new THREE.Box3()
        box.setFromObject(model)
        this.size = box.getSize(new THREE.Vector3()).length()
        this.center = box.getCenter(new THREE.Vector3())

        this.trigger('newBase')
    }
}