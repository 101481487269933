import * as THREE from 'three'
import Experience from '../Experience'
import EventEmitter from '../Utils/EventEmitter'
import Point from './Point.js'

export default class Points extends EventEmitter {
    constructor() {
        super()

        this.experience = new Experience()
        this.screenSpace = this.experience.screenSpace
        this.camera = this.experience.camera
        this.scene = this.experience.scene

        this.children = []
        this.activePoints = []
        this.raycaster = new THREE.Raycaster()

        this.hidden = false

        this.div = document.body.querySelector('.points')
        this.setPoints()
    }

    getActivePoints() {
        this.activePoints = []
        this.children.forEach((point) => {
            const p = point.getActivePoints()
            this.activePoints = this.activePoints.concat(p)
        })
    }

    exit() {
        if (this.children[0].active) {
            return true
        } else {
            this.trigger('hierarchyChange', ['initial'])
            this.activePoints.forEach((point) => {
                point.html.classList.remove('visible')
            })
            this.children.forEach((point) => point.exit())
            this.getActivePoints()
        }
    }

    emitEvent(name, endOfTree) {
        if (endOfTree) {
            this.trigger('click', [name])
        } else {
            this.activePoints.forEach((point) => {
                point.html.classList.remove('visible')
                point.active = false
            })
            this.trigger('hierarchyChange', [name])
            this.getActivePoints()
        }
    }

    toggleHide() {
        this.hidden = !this.hidden
        this.div.style.display = this.hidden ? 'none' : 'block'
    }

    update() {
        this.activePoints.forEach((point) => {
            const screenPosition = point.position.clone()
            screenPosition.project(this.camera.instance)

            const translateX = screenPosition.x * this.screenSpace.width * 0.5
            const translateY = -screenPosition.y * this.screenSpace.height * 0.5
            point.html.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`

            this.raycaster.setFromCamera(screenPosition, this.camera.instance)
            const intersects = this.raycaster.intersectObjects(this.scene.children, true)

            const firstIntersect = intersects.find((intersect) => {
                return !intersect.isTransformControl
            })

            if (intersects.length === 0) {
                point.html.classList.add('visible')
            } else {
                const intersectionDistance = firstIntersect.distance
                const pointDistance = point.position.distanceTo(this.camera.instance.position)

                if (intersectionDistance < pointDistance) {
                    point.html.classList.remove('visible')
                } else {
                    point.html.classList.add('visible')
                }
            }
        })
    }

    setPoints() {
        const dailyGuests = new Point(
            this,
            'dailyGuests',
            new THREE.Vector3(-37.57202655017389, 34.4282637137669, 211.31167715331338),
            'Daily Guests'
        )
        dailyGuests.active = true

        const eventArea = new Point(
            this,
            'eventArea',
            new THREE.Vector3(-101.95836522768347, 25.016914943360817, 247.54157953612122),
            'Event Area'
        )
        eventArea.active = true

        const anfahrt = new Point(
            this,
            'anfahrt',
            new THREE.Vector3(21.422375700212584, 27.318976486220322, 330.7426584404908),
            'Anfahrt'
        )
        anfahrt.active = true

        this.getActivePoints()

        // Daily Guests
        new Point(
            dailyGuests,
            'kueche',
            new THREE.Vector3(-22.637631466890973, 9.45391209571228, 212.9758258719897),
            'Küche'
        )

        new Point(
            dailyGuests,
            'schlange',
            new THREE.Vector3(-11.19123937574279, 11.320767034528423, 210.47149412567765),
            'Beton-Schlange'
        )
        new Point(dailyGuests, 'toilette_tribune', new THREE.Vector3(7, 13, 203), 'Toiletten')
        new Point(
            eventArea,
            'wc',
            new THREE.Vector3(-194.32305960308514, 22.7395643626678, 198.4788884635453),
            'Toilette'
        )
        new Point(
            dailyGuests,
            'kasse',
            new THREE.Vector3(-18.43652999463273, 11.113276845326828, 195.90901618688116),
            'Kasse'
        )

        new Point(
            dailyGuests,
            'vip',
            new THREE.Vector3(-20.01473745603319, 11.228504017067301, 186.3566403793342),
            'VIP Area'
        )

        new Point(
            dailyGuests,
            'tribuene',
            new THREE.Vector3(-8.665739203231375, 26.905266949847796, 199.40377204899272),
            'Tribüne'
        )
        new Point(
            dailyGuests,
            'strand',
            new THREE.Vector3(-30.334107042625824, 13.80746610190766, 195.60453256047782),
            'Strand'
        )

        // Event Area

        new Point(
            eventArea,
            'volleyball',
            new THREE.Vector3(-118.49527726050104, 10.423521182168463, 246.03141085909178),
            'Volleyball'
        )
        new Point(
            eventArea,
            'fussball',
            new THREE.Vector3(-105.65206583321692, 5, 264.5790108206538),
            'Fußball'
        )
        new Point(
            eventArea,
            'disco',
            new THREE.Vector3(-99.89821548129902, 9, 244.3158280853344),
            'Disco'
        )
        new Point(
            eventArea,
            'zelt',
            new THREE.Vector3(-87.26493597722488, 11, 234.02453443803523),
            'Event-Zelt'
        )
        new Point(
            eventArea,
            'hinterhaus',
            new THREE.Vector3(-110.50071362550864, 15.550402851913995, 209.4825744065964),
            'Toiletten'
        )
        new Point(
            eventArea,
            'kasse2',
            new THREE.Vector3(-103.74213786941998, 12.31364663269228, 217.8367329722967),
            'Kasse unten'
        )

        // Anfahrt

        new Point(
            anfahrt,
            'wendehammer',
            new THREE.Vector3(2.3634524141351037, 14.63469448777337, 341.91341860453576),
            'Wendehammer'
        )
        new Point(
            anfahrt,
            'parkplatz',
            new THREE.Vector3(75.71128907024404, 9.141286930625519, 298.8749020234958),
            'Parkplatz'
        )
        new Point(
            anfahrt,
            'unterfuehrung',
            new THREE.Vector3(-10.662193134858107, 12.251422059422602, 301.07900062488613),
            'Unterführung'
        )
    }
}
