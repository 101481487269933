import * as dat from 'lil-gui'
import Stats from 'stats.js'
import DebugSwitch from './DebugSwitch.js'
import PositionHelper from './PositionHelper.js'

export default class Debug {
    constructor() {
        this.active = true
        // Debug UI
        this.ui = new dat.GUI()

        // FPS Meter
        this.stats = new Stats()
        this.stats.showPanel(0)
        document.body.appendChild(this.stats.dom)

        this.hide()
        this.setSwitch()
    }
    
    setPositionHelper( ) {
        this.positionHelper = new PositionHelper(this)
    }

    setSwitch() {
        this.switch = new DebugSwitch()

        this.switch.onHide(() => {
            this.hide()
        })
        this.switch.onShow(() => {
            this.show()
        })
    }
    
    hide() {
        this.ui.hide()
        this.stats.dom.style.display = 'none'
        this.active = false
    }

    show() {
        this.ui.show()
        this.stats.dom.style.display = 'inherit'
        this.active = true
    }

    begin() {
        this.stats.begin()
    }

    end() {
        this.stats.end()
    }
}
