import Experience from "../Experience.js"
import Ground from "./Ground.js"
import Environment from "./Environment.js"

export default class World {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.ground = new Ground()
        this.environment = new Environment()
        this.ground.on('ready', () => this.environment.environmentMap.updateMaterials())
    }

    update() {
        // Call update() of animated Geemetry here
    }
}
