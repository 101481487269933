export default class Point {
    constructor(parent, id, position, label) {
        this.parent = parent
        this.parent.children.push(this)

        this.id = id
        this.position = position
        this.label = label
        this.div = this.parent.div
        this.html = this.createHTML()

        this.pointRenderer
        this.children = []
        this.active
        this.detailView = false

        this.html.addEventListener('click', () => this.click())
    }

    createHTML() {
        const node = document.createElement('div')
        node.classList.add('pointWrapper')
        node.innerHTML = `
                    <div class="center">
                    <div class="point"></div>
                    <div class="label"> ${this.label}</div>
                    </div>
                    `
        this.div.appendChild(node)
        return node
    }

    getActivePoints() {
        if (this.active) {
            return [this]
        } else {
            if (this.children.length == 0) return []
            let array = []
            this.children.forEach((point) => {
                array = array.concat(point.getActivePoints())
            })
            return array
        }
    }

    click() {
        if (this.children.length == 0) {
            this.parent.emitEvent(this.id, true)
            this.detailView = true
        } else {
            this.children.forEach((point) => (point.active = true))
            this.parent.emitEvent(this.id)
        }
    }

    emitEvent(name, endOfTree) {
        this.parent.emitEvent(name, endOfTree)
    }

    exit() {
        if (this.children.length == 0) return
        if (this.children[0].active) {
            this.children.forEach((point) => (point.active = false))
            this.parent.children.forEach((point) => (point.active = true))
        } else {
            this.children.forEach((point) => point.exit())
        }
    }

    appendChild(point) {
        this.children.push(point)
    }
}
