import * as THREE from 'three'
import Experience from '../Experience'

export default class Shadow {
    constructor() {
        this.experience = new Experience()
        this.renderer = this.experience.renderer
        this.sceneSizes = this.experience.sceneSizes

        this.sceneSizes.on('newBase', () => {
            this.lightSources.forEach((light) => {
                this.configureLigth(light)
                this.debugAdd(light)
            })
        })

        this.lightSources = new Set()
        this.prepareRenderer()

        this.debug()
    }

    prepareRenderer() {
        this.renderer.instance.shadowMap.autoUpdate = false
        this.renderer.instance.shadowMap.enabled = true
        this.renderer.instance.shadowMap.type = THREE.PCFSoftShadowMap
    }

    activate(input, cast, receive) {
        if (input.isLight) {
            this.configureLigth(input)
        } else {
            this.configureObjects(input, cast, receive)
        }
    }

    configureLigth(input) {
        if (!input.isDirectionalLight)
            console.warn('Template only supports Directional Lights for Shadows')

        this.lightSources.add(input)

        input.position.normalize()
        input.position.multiplyScalar(this.sceneSizes.size)

        input.castShadow = true
        input.shadow.camera.top = this.sceneSizes.size * 0.6
        input.shadow.camera.bottom = -this.sceneSizes.size * 0.6
        input.shadow.camera.left = -this.sceneSizes.size * 0.6
        input.shadow.camera.right = this.sceneSizes.size * 0.6
        input.shadow.camera.near = Math.floor(this.sceneSizes.size * 0.1)
        input.shadow.camera.far = Math.floor(this.sceneSizes.size * 1.3)
        input.shadow.camera.updateProjectionMatrix()
    }

    configureObjects(input, cast, receive) {
        cast = cast == undefined ? true : cast
        receive = receive == undefined ? true : receive

        input.traverse((child) => {
            if (child.castShadow == undefined) return
            child.castShadow = cast
            child.receiveShadow = receive
        })
    }

    update() {
        this.renderer.update()
        this.renderer.instance.shadowMap.needsUpdate = true
        this.renderer.update()
    }

    debug() {
        this.params = {
            update: () => {
                input.shadow.camera.updateProjectionMatrix()
                input.shadow.camera.helper.update()

            },
            reloadMap: () => {
                input.shadow.map.dispose() // important
                input.shadow.map = null
            },
            updateScene: () => {
                this.experience.scene.traverse((child) => {
                    if (
                        !(child instanceof THREE.Mesh) ||
                        !(child.material instanceof THREE.MeshStandardMaterial)
                    )
                        return
                    child.material.needsUpdate = true
                })
            },
        }

        const debug = this.experience.debug
        this.folder = debug.ui.addFolder('Shadow').close()

        this.folder
            .add(this.renderer.instance.shadowMap, 'enabled')
            .onChange(() => this.params.updateScene())
        
        this.folder.add(this, 'update')
        
        this.folder.add(this.renderer.instance.shadowMap, 'type').options({
            Basic: THREE.BasicShadowMap,
            PCF: THREE.PCFShadowMap,
            PCFSoft: THREE.PCFSoftShadowMap,
            VSM: THREE.VSMShadowMap,
        })
    }

    debugAdd(input) {
        this.folder
            .add(input.shadow.camera, 'top')
            .min(0)
            .max(this.sceneSizes.size * 2)
            .onFinishChange(() => this.params.update())
        this.folder
            .add(input.shadow.camera, 'bottom')
            .min(-this.sceneSizes.size * 2)
            .max(0)
            .onFinishChange(() => this.params.update())
        this.folder
            .add(input.shadow.camera, 'left')
            .min(-this.sceneSizes.size * 2)
            .max(0)
            .onFinishChange(() => this.params.update())
        this.folder
            .add(input.shadow.camera, 'right')
            .min(0)
            .max(this.sceneSizes.size * 2)
            .onFinishChange(() => this.params.update())
        this.folder
            .add(input.shadow.camera, 'zoom')
            .min(0)
            .max(10)
            .onFinishChange(() => this.params.update())
        this.folder.add(input.shadow.camera, 'near').onFinishChange(() => this.params.update())
        this.folder.add(input.shadow.camera, 'far').onFinishChange(() => this.params.update())
        this.folder.add(input.shadow, 'normalBias').min(0).max(5).step(0.01)
        this.folder.add(input.shadow, 'bias').min(0).max(2).step(0.01)
        this.folder.add(input.shadow, 'radius').min(1).max(5).step(0.1)
        this.folder
            .add(input.shadow.mapSize, 'height')
            .min(128)
            .max(8192)
            .onFinishChange(() => this.params.reloadMap())
        this.folder
            .add(input.shadow.mapSize, 'width')
            .min(128)
            .max(8192)
            .onFinishChange(() => this.params.reloadMap())
    }
}
