import EventEmitter from './EventEmitter.js'
import Experience from '../Experience.js'

export default class ScreenSpace extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.experience = new Experience()
        this.canvas = this.experience.canvas

        // Get's the size of the canvas defined by CSS
        this.width = this.canvas.clientWidth
        this.height = this.canvas.clientHeight

        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // Resize event
        window.addEventListener('load', () => this.resize())
        window.addEventListener('resize', () => this.resize())
    }
    
    resize(){
        this.width = this.canvas.clientWidth
        this.height = this.canvas.clientHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        this.trigger('resize')
    }
}

