import Experience from '../Experience'
import EventEmitter from './EventEmitter'

export default class MouseCoordinates extends EventEmitter {
    constructor() {
        super()

        this.pointer = {}
        this.experience = new Experience()
        this.screenSpace = this.experience.screenSpace

        window.addEventListener('mousemove', (event) => {
            this.pointer.x = (event.x / this.screenSpace.width) * 2 - 1
            this.pointer.y = -(event.y / this.screenSpace.height) * 2 + 1

            const e = {}
            e.x = this.pointer.x
            e.y = this.pointer.y

            this.trigger('move', [e])
        })
    }
}
