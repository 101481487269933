export default class DebugSwitch {
    constructor() {
        this.open = false
        this.callbacks = {}

        this.createCSS()
        this.createHTML()
        this.setListener()
    }

    createCSS() {
        this.closedCSS = `
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        height: 5em;
        width: 5em;
        cursor: context-menu;
        `
        this.openCSS = `
        position: absolute;
        top: 0.5em;
        right: 17em;
        background-color: rgb(108 175 207);
        color: white;
        padding: 0.2em 1em;
        border-radius: 6px;
        cursor: pointer;
        `
    }

    createHTML() {
        this.button = document.createElement('div')
        this.button.id = 'debugSwitch'
        this.button.style = this.closedCSS

        const parentDiv = document.body.querySelector('.canvas-container-map')
        parentDiv.appendChild(this.button)
    }

    setListener() {
        this.button.addEventListener('click', (e) => {
            if (this.open) {
                this.button.style = this.closedCSS
                this.button.innerHTML = ''
                this.trigger('hide')
                this.open = false
            }
        })

        this.button.addEventListener('mousedown', () => start())
        this.button.addEventListener('touchstart', () => start())

        this.button.addEventListener('mouseleave', () => destroy())
        document.addEventListener('mouseup', () => destroy())
        document.addEventListener('touchend', () => destroy())
        document.addEventListener('touchcancel', () => destroy())
        document.addEventListener('touchmove', () => destroy())

        const destroy = () => {
            if (this.open) return
            if (this.mouseTimer) {
                window.clearTimeout(this.mouseTimer)
            }
        }

        const start = () => {
            if (this.open) return
            this.mouseTimer = window.setTimeout(() => {
                this.button.style = this.openCSS
                this.button.innerHTML = 'Hide'
                this.trigger('show')
                this.open = true
            }, 1000)
        }
    }

    trigger(event) {
        if (event == 'show') {
            this.callbacks.show.forEach((callback) => callback.apply())
        } else {
            this.callbacks.hide.forEach((callback) => callback.apply())
        }
    }

    onShow(callback) {
        this.callbacks.show = []
        this.callbacks.show.push(callback)
    }

    onHide(callback) {
        this.callbacks.hide = []
        this.callbacks.hide.push(callback)
    }
}
