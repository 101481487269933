import Cards from './Cards'
import Fullscreen from './Fullscreen'
import Points from './Points'
import Experience from '../Experience'

export default class UI {
    constructor() {
        this.experience = new Experience()
        this.points = new Points(this)
        this.cards = new Cards(this)
        this.fullscreen = new Fullscreen(this)
        this.flyTarget = 'initial'
        this.points.on('hierarchyChange', (point) => {
            this.flyTarget = point
            this.startFlight()
        })
        this.points.on('click', (point) => {
            this.experience.cameraFlight[point].apply(
                this.experience.cameraFlight
            )
        })

        this.closeButton = document.body.querySelector('.close')
        this.closeButton.addEventListener('click', () => {
            if (this.cards.detailViewActive) {
                this.cards.exit()
                this.startFlight()
                return
            }

            if (this.points.exit()) this.fullscreen.exit()
        })
    }

    startFlight() {
        this.experience.cameraFlight[this.flyTarget].apply(
            this.experience.cameraFlight
        )
    }

    update() {
        this.points.update()
    }
}
