import { path } from 'animejs'
import * as THREE from 'three'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import _sources from './../../../static/sources/sources.json'

export default class Resources {
    constructor() {
        
        if(typeof TJSPath_map == 'undefined'){
            console.warn('No Path Variable available! Not using a Path Prefix')
            this.pathPrefix = '.'
        }else{
            this.pathPrefix = TJSPath_map
        }

        this.setLoaders()
        this.sources = this.getSources()
        this.initSyncLoading()
    }

    setLoaders() {
        this.manager = new THREE.LoadingManager()

        // DRACO GLTF Loader
        const DRACO = new DRACOLoader()
        DRACO.setDecoderPath(this.pathPrefix + '/draco/')

        const gltfLoader = new GLTFLoader()
        gltfLoader.setDRACOLoader(DRACO)

        // Texture Loader
        const textureLoader = new THREE.TextureLoader()

        // TODO: cubeMapTextureLoader

        // Set Handlers
        this.manager.addHandler(/\.glb|\.gltf/, gltfLoader)
        this.manager.addHandler(/\.png|\.tiff|\.jpeg|\.jpg/, textureLoader)
    }

    getSources() {
        const sources = []

        _sources.forEach((path) => {
            // Compile items for every path in sources.json
            const source = {}

            // Set name to filename to string between '/' and '.'
            source.name = path.match(/[a-z0-9-_() A-Z]*(?=\.)/)[0]

            // Test if filename contains '.async'
            source.async = /\.async/.test(path) ? true : false
            source.path = this.pathPrefix + path
            sources.push(source)
        })
        return sources
    }

    load(path, callback) {
        // Let Loading Manager handle the selection of the right loader
        this.manager.getHandler(path).load(path, (items) => {
            callback.apply(this, [items])
        })
    }

    async initSyncLoading() {
        this.syncItems = {}
        this.loaded = 0
        this.toLoad = 0

        // Count how many sources in sources array are loaded synchronously
        this.sources.forEach((source) => {
            if (source.async) return
            this.toLoad++
        })

        if (this.loaded == this.toLoad) return

        this.syncLoading = new Promise((resolve) => {
            this.sources.forEach((source) => {
                if (source.async) return

                this.load(source.path, (items) => {
                    // Save loaded items in syncItems items
                    this.syncItems[source.name] = items
                    this.loaded++

                    // When all syncItems are loaded resolve Promise
                    if (this.loaded == this.toLoad) {
                        resolve(true)
                    }
                })
            })
        })
    }

    async get(name) {
        const source = this.sources.find((source) => {
            return source.name == name
        })

        // Throw error if no file was found that is called 'name'
        if (source == undefined) console.warn(name + ' no such file available')

        if (!source.async) {
            // Wait for all SyncItems to be loaded until all Sync Files are Loaded
            await this.syncLoading
            return this.syncItems[name]
        } else {
            // Load item and return item as soon as Loaded
            return await new Promise((resolve) => {
                this.load(source.path, (items) => resolve(items))
            })
        }
    }

    getSyncList() {
        const list = []
        this.sources.forEach((source) => {
            if (source.async) return
            list.push(source.name)
        })
        return list
    }

    getAsyncList() {
        const list = []
        this.sources.forEach((source) => {
            if (!source.async) return
            if (!/\.glb|\.gltf/.test(source.path)) return
            list.push(source.name)
        })
        return list
    }
}
