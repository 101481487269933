export default class Cards {
    constructor(ui) {
        this.points = ui.points

        this.detailViewActive = false

        this.cards = document.body.querySelectorAll('.three-card')

        this.points.on('click', (point) => {
            this.closeAll()
            this.detailViewActive = true

            this.cards.forEach((card) => {
                if (card.id != point) return
                card.style.opacity = 0.8
                card.style.display = 'block'
            })
        })
    }

    exit() {
        this.detailViewActive = false
        this.closeAll()
    }

    closeAll() {
        this.cards.forEach((card) => {
            card.style.opacity = 0
            card.style.display = 'none'
        })
    }
}
