import * as THREE from 'three'
import Experience from '../Experience'
import EventEmitter from '../Utils/EventEmitter'

export default class Ground extends EventEmitter{
    constructor() {
        super()
        this.experience = new Experience()
        this.shadow = this.experience.shadow
        this.scene = this.experience.scene
        this.sceneSizes = this.experience.sceneSizes
        this.resources = this.experience.resources

        this.resources.get('ground').then((source) => {
            this.geometry = source.scene

            this.shadow.activate(this.geometry)
            this.sceneSizes.setBase(this.geometry)

            this.scene.add(this.geometry)

            this.loadLeftoverGeometry()
        })
    }

    loadLeftoverGeometry() {
        const list = this.resources.getAsyncList()
        const length = list.length

        list.forEach(async (entry, index) => {
            if (entry == 'maple2') return
            let geo = await this.resources.get(entry)
            geo = geo.scene
            this.shadow.activate(geo)

            this.scene.add(geo)
            if (length == index + 1){
                this.ready = true
                this.trigger('ready')
                this.shadow.update()
            }
        })
    }

    isReady() {
        return this.ready
    }
}
