import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Parallax from './Utils/Parallax.js'

export default class Camera {
    constructor() {
        this.experience = new Experience()
        this.screenSpace = this.experience.screenSpace
        this.scene = this.experience.scene
        this.sceneSizes = this.experience.sceneSizes
        this.canvas = this.experience.canvas

        this.setInstance()
        this.setControls()

        // Experimented with Parallax
        // this.parallax = new Parallax(this.instance)

        this.sceneSizes.on('newBase', () => this.setByBoundingBox())
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.screenSpace.width / this.screenSpace.height,
            0.1,
            2000
        )

        this.instance.position.set(133, 1484, 547)

        this.scene.add(this.instance)
    }

    setByBoundingBox() {
        this.instance.far = this.sceneSizes.size * 100

        this.instance.updateProjectionMatrix()
    }

    setControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.target.set(134, -84, 327)

        this.controls.enabled = false;
        
        this.controls.mouseButtons.LEFT = THREE.MOUSE.PAN,

        this.controls.touches.ONE = THREE.TOUCH.PAN

        this.controls.screenSpacePanning = false
        this.controls.panSpeed = 2

        this.controls.enableZoom = false

        // Controls adjustment
        // this.controls.enableDamping = true
        // this.controls.dampingFactor = 0.3

        this.controls.update()
    }

    resize() {
        this.instance.aspect = this.screenSpace.width / this.screenSpace.height
        this.instance.updateProjectionMatrix()
    }

    update() {
        this.controls.update()
        // this.parallax.update()
    }

    // Debug
    debug() {
        const debug = this.experience.debug
        debug.setPositionHelper()
        const debugFolder = debug.ui.addFolder('Orbit Controls').close()

        debugFolder.add(this.controls, 'enabled')
        debugFolder.add(this.controls, 'enableRotate')
        debugFolder.add(this.controls, 'enablePan')
        debugFolder.add(this.controls, 'screenSpacePanning')
        debugFolder.add(this.controls, 'enableZoom')
        debugFolder.add(this.controls, 'autoRotate')
        debugFolder.add(this.controls, 'autoRotateSpeed').min(0).max(5).step(0.05)
        debugFolder.add(this.controls, 'enableDamping')
        debugFolder.add(this.controls, 'dampingFactor').min(0).max(1).step(0.01)
        debugFolder.add(this.controls, 'keyPanSpeed').min(0).max(1).step(0.01)
        debugFolder.add(this.instance, 'near').min(0).max(1).step(0.01)
        debugFolder.add(this.instance, 'far').min(0).max(5000).step(100)
    }
}
