import * as THREE from 'three'
import Experience from '../Experience.js'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js'

export default class Environment {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sceneSizes = this.experience.sceneSizes
        this.resources = this.experience.resources
        this.shadow = this.experience.shadow

        this.setSunLight()

        this.resources.get('studio_055').then((sources) => {
            // Cubemap or HDR has to be loaded first
            this.sources = sources

            this.setBackground()
            this.setEnvironmentMap()
            this.debug()
        })
    }

    setSunLight() {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 3)
        this.sunLight.position.set(-46, 290, 0)
        this.sunLight.position.multiplyScalar(this.sceneSizes.size)

        // Shadow (Remove when not needed)
        this.sunLight.shadow.normalBias = 0.5
        this.shadow.activate(this.sunLight)
        this.sunLight.shadow.mapSize.height = 8192
        this.sunLight.shadow.mapSize.width = 8192

        this.scene.add(this.sunLight)
    }

    setBackground() {
        this.scene.background = this.sources
        this.scene.background.mapping = THREE.EquirectangularReflectionMapping
    }

    setEnvironmentMap() {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.2
        this.environmentMap.texture = this.sources
        this.environmentMap.texture.encoding = THREE.sRGBEncoding

        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () => {
            this.scene.traverse((child) => {
                if (
                    child instanceof THREE.Mesh &&
                    child.material instanceof THREE.MeshStandardMaterial
                ) {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }

        this.environmentMap.updateMaterials()
    }

    debug() {
        const debug = this.experience.debug
        const debugFolder = debug.ui.addFolder('Environment').close()

        // Directional Light
        const camera = this.experience.camera
        const transformControls = new TransformControls(camera.instance, this.experience.canvas)
        transformControls.attach(this.sunLight)
        transformControls.enabled = true

        transformControls.addEventListener('dragging-changed', function (event) {
            if(!camera.controls.enabled)
            camera.controls.enabled = !event.value
        })
        this.scene.add(transformControls)

        // Light Helper
        const directionalLightCameraHelper = new THREE.CameraHelper(this.sunLight.shadow.camera)
        this.sunLight.shadow.camera.helper = directionalLightCameraHelper
        this.scene.add(directionalLightCameraHelper)
        this.toggleLightHelper = () => {
            directionalLightCameraHelper.update()
            directionalLightCameraHelper.visible = !directionalLightCameraHelper.visible
            transformControls.enabled = !transformControls.enabled
            transformControls.visible = !transformControls.visible

            const pos = this.sunLight.position
        }

        this.toggleLightHelper()

        debugFolder.add(this, 'toggleLightHelper')

        debugFolder
            .add(this.sunLight.position, 'x')
            .name('sunLightpositionX')
            .min(-10000)
            .max(10000)
            .step(0.01)
        debugFolder
            .add(this.sunLight.position, 'y')
            .name('sunLightpositionY')
            .min(-10000)
            .max(10000)
            .step(0.01)
        debugFolder
            .add(this.sunLight.position, 'z')
            .name('sunLightpositionZ')
            .min(-10000)
            .max(10000)
            .step(0.01)

        debugFolder
            .add(this.sunLight, 'intensity')
            .name('sunLightIntensity')
            .min(0)
            .max(10)
            .step(0.01)

        // Environment
        debugFolder
            .add(this.environmentMap, 'intensity')
            .name('envMapIntensity')
            .min(0)
            .max(4)
            .step(0.001)
            .onChange(this.environmentMap.updateMaterials)
    }
}
