import * as THREE from 'three'

import ScreenSpace from './Utils/ScreenSpace.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import Debug from './Utils/Debug/Debug.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import SceneSizes from './Utils/SceneSize.js'
import Shadow from './World/Shadow.js'
import CameraFlight from './World/CameraFlight.js'
import UI from './UI/UI.js'
import MouseCoordinates from './Utils/MouseCoordinates.js'

let instance = null

export default class Experience {
    constructor(_canvas) {
        // Singleton
        if (instance) {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // Setup
        this.debug = new Debug()

        this.screenSpace = new ScreenSpace()
        this.time = new Time()
        this.mouseCoordinates = new MouseCoordinates()

        this.sceneSizes = new SceneSizes()
        this.scene = new THREE.Scene()

        this.camera = new Camera()
        this.camera.debug()

        this.renderer = new Renderer()

        this.shadow = new Shadow()
        this.resources = new Resources()
        this.world = new World()

        this.cameraFlight = null
        this.ui = new UI()
        this.cameraFlight = new CameraFlight()

        // Resize event
        this.screenSpace.on('resize', () => {
            this.resize()
        })

        this.time.on('tick', () => {
            this.update()
        })
    }

    update() {
        this.camera.update()
        this.world.update()
        this.ui.update()
        this.renderer.update()

        if (this.debug.active) {
            this.debug.end()
            this.debug.begin()
        }
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    // destroy() {
    //     this.sizes.off("resize")
    //     this.time.off("tick")

    //     // Traverse the whole scene
    //     this.scene.traverse((child) => {
    //         // Test if it's a mesh
    //         if (child instanceof THREE.Mesh) {
    //             child.geometry.dispose()

    //             // Loop through the material properties
    //             for (const key in child.material) {
    //                 const value = child.material[key]

    //                 // Test if there is a dispose function
    //                 if (value && typeof value.dispose === "function") {
    //                     value.dispose()
    //                 }
    //             }
    //         }
    //     })

    //     this.camera.controls.dispose()
    //     this.renderer.instance.dispose()

    //     if (this.debug.active) this.debug.ui.destroy()
    // }
}
